import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data-service/data.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('amenitiesanimations',
      [
        state('state1',
          style(
            {
              opacity: 0,
              overflow: 'hidden'
            }
          )
        ),
        state('state2',
          style(
            {
              opacity: 1,
              transform: 'translateY(0)'
            }
          )
        ),
        transition('state1 <=> state2', animate('0.5s')),
        transition('state2 <=> state1', animate('0.1s')),
      ]
    )
  ]
})
export class LoaderComponent implements OnInit {
  showingImage = 1;
  public state1 = 'state2';
  public state2 = 'state1';
  public state3 = 'state1';
  public state4 = 'state1';
  constructor(private dataService: DataService) { }

  ngOnInit() {
    setInterval(() => {
      if (this.showingImage > 3) {
        this.showingImage = 1;
      } else {
        this.showingImage = this.showingImage + 1
      }
      switch (this.showingImage) {
        case 1: this.state1 = 'state2';
          this.state2 = 'state1';
          this.state3 = 'state1';
          this.state4 = 'state1';
          break;
        case 2: this.state1 = 'state1';
          this.state2 = 'state2';
          this.state3 = 'state1';
          this.state4 = 'state1';
          break;
        case 3: this.state1 = 'state1';
          this.state2 = 'state1';
          this.state3 = 'state2';
          this.state4 = 'state1';
          break;
        case 4: this.state1 = 'state1';
          this.state2 = 'state1';
          this.state3 = 'state1';
          this.state4 = 'state2';
          break;
        default: this.state1 = 'state2';
          this.state2 = 'state1';
          this.state3 = 'state1';
          this.state4 = 'state1';
          break;
      }
    }, 600)
  }

  get loaderMessage(): string {
    return this.dataService.loaderMessage;
  }

}
