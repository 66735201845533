<header id="main-header">
  <div class="header-top clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <a class="logo" href="#" *ngIf="logo">
            <img class="img-responsive" src="assets/img/viagoose-logo.png" alt="Viagoose - Logo"
              title="Viagoose - Logo" />
          </a>
        </div>
        <div class="col-md-5 pull-right sign-in">
          <div class="top-user-area clearfix">
            <ul class="top-user-area-list list list-horizontal list-border" *ngIf="!mobileMenu">
              <li class="top-user-area-avatar" *ngIf="loginStatus">
                <a [routerLink]="['user-details']" data-toggle="tooltip" title="View & update profile detials">Hi
                  {{userName}}</a>
              </li>
              <li *ngIf="loginStatus">
                <a [routerLink]="['booking-history']" data-toggle="tooltip" title="View & amend bookings">
                  My Bookings
                </a>
              </li>
              <li *ngIf="loginStatus" style="cursor: pointer;">
                <a (click)="logout()" data-toggle="tooltip" title="Log out from this session">
                  Log out
                </a>
              </li>
            </ul>
          </div>
        </div>
        <img *ngIf="loginStatus && userData.retailer_badge" [src]="userData.retailer_badge"
          class="img-fluid rounded-circle retailer-badge retailer-badge-a" (error)="userData.retailer_badge=false">
      </div>

    </div>

  </div>
  <ng-container *ngIf="loginStatus">
    <app-sub-header>
    </app-sub-header>
    <!-- <app-progress-header-bar></app-progress-header-bar> -->
  </ng-container>
</header>
