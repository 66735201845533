import {Injectable,Output} from '@angular/core';
import {EventEmitter} from 'protractor';
import {Observable,Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private _loaderStatus=false;
  private _loaderMessage='checking room availability';
  private _siteToken='';
  private _loginStatus: any;
  private _userData: any;
  private _hotelData: any;
  private _gustDetail: any[]=[];
  private _rateKey=0;
  private _selectedRooms=[];
  private _facilityList=[];
  private _facilityGroup=[];
  private _updateModalData: Subject<boolean>=new Subject<boolean>();

  constructor() {}

  public get loaderStatus(): boolean {
    return this._loaderStatus;
  }
  public set loaderStatus(value: boolean) {
    this._loaderStatus=value;
  }
  public get loaderMessage(): string {
    return this._loaderMessage;
  }
  public set loaderMessage(value: string) {
    this._loaderMessage=value;
  }
  public get siteToken() {
    return this._siteToken;
  }
  public set siteToken(value) {
    this._siteToken=value;
  }
  public get loginStatus() {
    return this._loginStatus;
  }
  public set loginStatus(value) {
    this._loginStatus=value;
  }
  public get userData() {
    return this._userData;
  }
  public set userData(value) {
    this._userData=value;
  }
  public get gustDetail(): any[] {
    return this._gustDetail;
  }
  public set gustDetail(value: any[]) {
    this._gustDetail=value;
  }
  public get hotelData(): any {
    return this._hotelData;
  }
  public set hotelData(value: any) {
    this._hotelData=value;
  }
  public get rateKey() {
    return this._rateKey;
  }
  public set rateKey(value) {
    this._rateKey=value;
  }
  public get selectedRooms() {
    return this._selectedRooms;
  }
  public set selectedRooms(value) {
    this._selectedRooms=value;
  }
  public get facilityList() {
    return this._facilityList;
  }
  public set facilityList(value) {
    this._facilityList=value;
  }
  public get facilityGroup() {
    return this._facilityGroup;
  }
  public set facilityGroup(value) {
    this._facilityGroup=value;
  }
  public get updateModalData(): Observable<boolean> {
    return this._updateModalData.asObservable();
  }
  public updateModalDataEvent(value: boolean) {
    this._updateModalData.next(value);
  }

  format(num: any) {
    let b=num,hasSymbol=false;
    if(typeof num=='string') {
      hasSymbol=num.indexOf('£')>=0;
      num=num.replace(/(,|\s|£)/g,'');
      num=parseFloat(num);
    }
    if(isNaN(num)) {
      console.log(num);
      return b;
    }
    return (hasSymbol? '£':'')+num.toFixed(2).toLocaleString();
  }
}
