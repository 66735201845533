import {HttpClient,HttpHeaders,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment'
import {DataService} from '../data-service/data.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {

  // url = 'http://viagoose.iamaarvin.com/api.asmx';

  url='http://api.viagoose.com';

  // url = 'http://viagoose.azurewebsites.net/ViagooseAPI/API.asmx/';

  constructor(private httpClient: HttpClient,private dataService: DataService) {
  }

  get header() {
    let header=new HttpHeaders();
    if(this.dataService.siteToken) {
      header=header.set('Authorization','Bearer '+this.dataService.siteToken);
      if(this.dataService.loginStatus&&this.dataService.userData&&this.dataService.userData.authtoken) {
        header=header.set('Authtoken',this.dataService.userData.authtoken);
      }
      return header;
    } else {
      return header;
    }
  }

  get(url) {
    //avoid cache
    url+=(url.indexOf('?')>-1? '&':'?')+'time='+Date.now();
    return this.httpClient.get(environment.baseUrl+url,{headers: this.header})
  }

  post(url,data) {
    return this.httpClient.post(environment.baseUrl+url,data,{headers: this.header})
  }

  private extractData(res: Response) {
    const body=res.json();
    return body||{};
  }
  getWithUrl(url) {
    return this.httpClient.get(url);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message||error);
  }

}
