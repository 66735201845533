<div id="main-footer">
  <div class="container">
    <div class="row row-wrap">
      <div class="col-md-3">
        <a class="logo" href="#">
          <img src="assets/img/viagoose-logo.png" alt="Viagoose Logo" title="Viagoose Logo">
        </a>
      </div>
      <div class="col-md-3">
        <h4>More Information</h4>
        <ul class="list list-footer">
          <li><a [routerLink]="['about-us']">About US</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <h4>Discover</h4>
        <ul class="list list-footer">
          <li>
            <!-- <a [routerLink]="['jobs']">Jobs</a> -->
          </li>
          <li><a [routerLink]="['/privacy-policy']">Privacy &amp; Cookies Policy</a>
          </li>
          <li>
            <a [routerLink]="['/terms-condition']">General Terms &amp; Conditions</a>
          </li>
          <li><a [routerLink]="['faq']">FAQ</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <h4>Have Questions?</h4>
        <h4><a href="mailto:info@viagoose.com" class="text-color">info@viagoose.com </a></h4>
      </div>
    </div>
  </div>

</div>