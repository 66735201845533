<app-header></app-header>
<router-outlet>
</router-outlet>
<ng-container *ngIf="showLoader">
  <app-loader></app-loader>
</ng-container>

<app-footer></app-footer>

<app-point-balance></app-point-balance>
