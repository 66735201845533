import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SiteConfig } from '../config/site-config';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private _router: Router) { }
    canActivate(): boolean {
        const loginStatus = localStorage.getItem(SiteConfig.localStorageNames.viagooseLoginStatus)
        if (loginStatus == 'true') {
            return true
        }
        // navigate to login page
        this._router.navigate(['/home']);
        // you can save redirect url so after authing we can move them back to the page they requested
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private _router: Router) { }
    canActivate(): boolean {
        const loginStatus = localStorage.getItem(SiteConfig.localStorageNames.viagooseLoginStatus)
        if (loginStatus == 'true') {
            this._router.navigate(['/search']);
        }
        // you can save redirect url so after authing we can move them back to the page they requested
        return true;
    }
}
