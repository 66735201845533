import {Component,OnInit} from '@angular/core';
import {Router} from '@angular/router';
// import {DeviceDetectorService} from 'ngx-device-detector';
import {ApiService} from 'src/app/services/api-service/api.service';
import {CommonService} from 'src/app/services/common-service/common.service';
import {DataService} from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public faqs: any=[];

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    // private router: Router,
    // private dataService: DataService,
  ) {
    this.faqs=JSON.parse(sessionStorage.getItem('FAQ'));
    if(!this.faqs) this.getFAQ();
  }

  ngOnInit(): void {
  }

  getFAQ() {
    this.faqs=[];
    this.apiService.get('customer/faqs').subscribe(resp => {
      let faqs=resp['data'],sFaq: object={};

      faqs.forEach((f: object) => {
        let type=f['type'];
        if(typeof sFaq[type]=='undefined') sFaq[type]=[];
        sFaq[type].push(f);
      })

      for(let j in sFaq) {
        let fq=sFaq[j];
        this.faqs.push({type: j,faqs: fq});
      }
      sessionStorage.setItem('FAQ',JSON.stringify(this.faqs));
    })
  }

  indentationNL(text) {
    return text.replace(/(\n|<br>)/g,'$1&nbsp;&nbsp;&nbsp;&nbsp;');
  }

}
