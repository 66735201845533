<div class="container-fluid save-loader">
  <div class="row text-center loader-text">
    <h4 style="font-family: 'Roboto', arial, helvetica, sans-serif;
    margin-top: 0;
    font-weight: 300;
    color: #565656;
    line-height: 1.3em;color: white;">
      Stay with us
    </h4>
    <h4 style="    font-family: 'Roboto', arial, helvetica, sans-serif;
    margin-top: 0;
    font-weight: 300;
    color: #565656;
    line-height: 1.3em;color: white;">
      Lofty is {{loaderMessage}}
    </h4>
  </div>
  <div class="row l-img-row">
    <div class="col-md-3 col-lg-3 col-3 loader-img">
      <img src="../assets/img/viagoose-lofty.png" alt="" class="l-image" [@amenitiesanimations]=state1>
    </div>
    <div class="col-md-3 col-lg-3 col-3 loader-img">
      <img src="../assets/img/viagoose-lofty.png" alt="" class="l-image" [@amenitiesanimations]=state2>
    </div>
    <div class="col-md-3 col-lg-3 col-3 loader-img">
      <img src="../assets/img/viagoose-lofty.png" alt="" class="l-image" [@amenitiesanimations]=state3>
    </div>
    <div class="col-md-3 col-lg-3 col-3 loader-img">
      <img src="../assets/img/viagoose-lofty.png" alt="" class="l-image" [@amenitiesanimations]=state4>
    </div>
  </div>
</div>