import {Component,OnInit} from '@angular/core';
import {Data,Router} from '@angular/router';
import {Location} from '@angular/common';
import {DataService} from '../../services/data-service/data.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SiteConfig} from 'src/app/config/site-config';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
  public submenu: string='';
  public route: string;
  public isSearch: boolean;

  public showMenu: boolean=false;
  public isMobile: boolean;

  constructor(private location: Location,private router: Router,private dataService: DataService,public deviceDetector: DeviceDetectorService) {
    this.router.events.subscribe((val) => {
      if(location.path()!='') {
        this.route=location.path();
        switch(this.route) {
          case "/hotel-search":
            this.submenu='search-hotel';
            break;
          case "/how-it-works":
            this.submenu='howitsworks';
            break;
          case "/explainer-video":
            this.submenu='video';
            break;
          case "/booking-history":
            this.submenu='booking';
            break;
          case "/referral-programme":
            this.submenu='referal';
            break;
          case "/about-us":
            this.submenu='aboutus';
            break;
          case "/contact-us":
            this.submenu='contactus';
            break;
          case "/faq":
            this.submenu='faq';
            break;
          default:
            this.submenu='';
            break;
        }
      }

      this.isSearch=this.route.indexOf('search-result')>0;
    });

    this.isMobile=deviceDetector.isMobile()||deviceDetector.isTablet();

  }

  ngOnInit() {
  }

  get loginStatus() {
    return this.dataService.loginStatus
  }

  get userData() {
    return this.dataService.userData
  }
  get userName() {
    if(this.dataService&&this.dataService.userData&&this.dataService.userData.name) {
      const name=this.dataService.userData.name.split(' ');
      return name[0];
    }
  }

  logout() {
    localStorage.removeItem(SiteConfig.localStorageNames.viagooseLoginStatus);
    localStorage.removeItem(SiteConfig.localStorageNames.viagooseLoginData);
    sessionStorage.clear();
    this.dataService.loginStatus=false;
    this.dataService.userData=null;
    this.router.navigateByUrl('/login');
  }

}
