<div class="modal fade" id="balance-modal" role="dialog">
  <div class="modal-dialog mw-100 w-75 mx-auto">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Points Balance & History</h4>
        <button type="button" class="mx-3 my-2 border-0" (click)="pointHistory()">
          <i class="fa fa-refresh"></i>
        </button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="userPoints">
          <div class="row py-3">
            <div class="col-6">
              <h5>Points Balance : &nbsp; {{format(userPoints.balance_points, true)}}</h5>
            </div>
            <div class="col-6">
              <h5>Total given to Charity : &nbsp; £{{format(charitySum)|number:'0.2-2'}}</h5>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered table-striped dataTable text-center multi-header">
              <thead>
                <tr>
                  <th width="200px" rowspan="2">Date</th>
                  <th width="100px" rowspan="2">Order No</th>
                  <th rowspan="2" width="250px">Reservation ID</th>
                  <th rowspan="2" *ngIf="user?.retailer_name != 'Not A Fan'">{{user?.retailer_name}} FC (&pound;)</th>
                  <th colspan="4">Points</th>
                  <th [attr.colspan]="5" rowspan="2">
                    Charity
                  </th>
                  <th colspan="2">Closing</th>
                </tr>
                <tr>
                  <th width="100px">Start</th>
                  <th width="100px">Earned</th>
                  <th width="100px">Redeem</th>
                  <th width="100px">Donated</th>
                  <th width="100px">Points</th>
                  <th width="100px">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of userPoints.data">
                  <td class="text-center">{{formatDate(history.book_date)}}</td>
                  <td class="text-center">{{history.id}}</td>
                  <td class="text-center">
                    {{history.booking_id}}
                    <p class="text-danger text-center" *ngIf="history.status == 'Cancelled'">
                      Cancelled
                    </p>
                  </td>
                  <td class="text-center" *ngIf="user?.retailer_name != 'Not A Fan'">
                    &pound;{{format(history.retailer_earnings)}}</td>
                  <td class="text-center">{{format(history.current_balance,true)}}</td>
                  <td class="text-center">{{format(history.earned,true)}}</td>
                  <td class="text-center">{{format(history.redeem_amount * 100,true)}}</td>
                  <td class="text-center">{{sum(history.charity_donations)}}</td>
                  <td colspan="5" class="p-1 text-center">
                    <p *ngFor="let charity of history.charity_donations">{{charity.name}}</p>
                  </td>
                  <td class="text-center">{{format(history.closing_points,true)}}</td>
                  <td class="text-center">£{{format(history.closing_value)}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-center" *ngIf="user?.retailer_name != 'Not A Fan'">
                    &pound;{{format(total_retailer_earnings)}}</td>
                  <td colspan="3"></td>
                  <td class="text-center">{{format(charitySum*100,true)}}</td>
                  <td colspan="7"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
