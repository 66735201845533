import {BrowserModule,HammerModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import {SharedService} from './services/shared.service/shared.service';
import {DataService} from './services/data-service/data.service';
import {ApiService} from './services/api-service/api.service';
import {CommonService} from './services/common-service/common.service';
import {LoaderComponent} from './components/loader/loader.component';
import {SubHeaderComponent} from './components/sub-header/sub-header.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {ProgressHeaderBarComponent} from './components/progress-header-bar/progress-header-bar.component';
import {ToastrModule} from 'ngx-toastr';
import {FaqComponent} from './components/faq/faq.component';
import {PointBalanceComponent} from './components/point-balance/point-balance.component';
import {NgxDateRangeModule} from 'ngx-daterange';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoaderComponent,
    SubHeaderComponent,
    FooterComponent,
    ProgressHeaderBarComponent,
    FaqComponent,
    PointBalanceComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDateRangeModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HammerModule,
    AppRoutingModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,useClass: HttpConfigInterceptor,multi: true},
    SharedService,
    DataService,
    ApiService,
    CommonService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
