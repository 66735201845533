import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, retryWhen, mergeMap, take } from 'rxjs/operators';
import { SharedService } from '../services/shared.service/shared.service';
import { CommonService } from '../services/common-service/common.service';
import { Router } from '@angular/router';
import { SiteConfig } from '../config/site-config';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private sharedService: SharedService, private commonService: CommonService, private router: Router) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                if (error.status == 401) {
                    if (error.error.message === 'Authtoken Expired') {
                        localStorage.setItem(SiteConfig.localStorageNames.viagooseLoginStatus, 'false');
                        localStorage.removeItem(SiteConfig.localStorageNames.viagooseLoginData);
                        this.router.navigate(['/login']);
                    } else {
                        this.sharedService.getSiteToke().subscribe((response: any) => {
                            if (response && response.token) {
                                this.commonService.setSiteData(response.token);
                            }
                        });
                    }
                }
                return throwError(error);
            }), retryWhen((errors: Observable<any>) => errors.pipe(
                mergeMap((error, index) => {
                    // any other error than 401 with {error: 'invalid_grant'} should be ignored by this retryWhen
                    if (error.status !== 401) {
                        return throwError(error);
                    }
                    if (index === 0) {
                        // first time execute refresh token logic...
                    }

                    //   this.authService.logout();
                    return throwError(error);
                }),
                take(2)
                // first request should refresh token and retry,
                // if there's still an error the second time is the last time and should navigate to login
            )),
        );
    }
}