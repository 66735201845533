
import {Injectable} from '@angular/core';
import {ApiService} from '../api-service/api.service';
import {CommonService} from '../common-service/common.service';
import {DataService} from '../data-service/data.service';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private apiService: ApiService,private data: DataService) {
  }

  getSiteToke() {
    return this.apiService.get('token')
  }

  getPointBalance() {
    return this.apiService.get('customer/pointhistory');
  }

  roundAmt(amt) {
    let amount=Math.floor(amt);
    return this.data.format(amount);
  }

  fetchPointSettings() {
    return this.apiService.get('customer/getsettings');
  }
}
