<div class="w-100">
  <div class="nav sys-view" *ngIf="!isMobile">
    <ul class="slimmenu" id="slimmenu" style="font-size: xx-small;">
      <li [ngClass]="submenu=='search-hotel'?'active':''"><a href="#" [routerLink]="['/hotel-search']">Search & Book</a>
      </li>
      <li [ngClass]="submenu=='booking'?'active':''"><a href="#" *ngIf="loginStatus"
          [routerLink]="['/booking-history']">My Booking</a></li>
      <!-- <li [ngClass]="submenu=='howitsworks'?'active':''"><a href="#" [routerLink]="['/how-it-works']">How it Works</a>
      </li> -->
      <li [ngClass]="submenu=='aboutus'?'active':''"><a href="#" [routerLink]="['/about-us']">About Us</a></li>
      <!-- <li [ngClass]="submenu=='terms'?'active':''"><a href="#" [routerLink]="['/terms-condition']">Terms & Condition</a></li>
      <li [ngClass]="submenu=='privacy'?'active':''"><a href="#" [routerLink]="['/privacy-policy']">Privacy Policy</a></li> -->
      <li [ngClass]="submenu=='contactus'?'active':''"><a href="#" [routerLink]="['/contact-us']">Contact Us</a></li>
      <li [ngClass]="submenu=='faq'?'active':''"><a href="#" [routerLink]="['/faq']">FAQ</a></li>
      <li *ngIf="loginStatus">
        <app-progress-header-bar></app-progress-header-bar>
      </li>
    </ul>
  </div>

  <div class="nav mob-view" *ngIf="isMobile">
    <div class="w-100">
      <div class="d-flex">
        <div class="col-2">
          <a (click)="showMenu=!showMenu">
            <img src="../../../assets/img/{{showMenu ? 'close.png' : 'menu.png'}}" alt="menu"
              style="height:35px;width:35px;"> Menu
          </a>
        </div>
        <div class="col-6">
          <div class="p-0 m-0 w-100" *ngIf="loginStatus">
            <app-progress-header-bar></app-progress-header-bar>
          </div>
        </div>
        <div class="col-4 text-right pt-2 pr-4">Hi {{userName}}</div>
      </div>

      <ul style="position: absolute;z-index: 1000;list-style: none;" class="list-group w-100" *ngIf="showMenu">
        <li [ngClass]="{active : (submenu=='search-hotel'), 'list-group-item' :true , 'rounded-0' : true}"
          [routerLink]="['/hotel-search']" (click)="showMenu=false">
          Search & Book
        </li>
        <li [ngClass]="{active : (submenu=='booking'), 'list-group-item' :true }" *ngIf="loginStatus"
          [routerLink]="['/booking-history']" (click)="showMenu=false">
          My Bookings
        </li>
        <li *ngIf="loginStatus" class="list-group-item" [routerLink]="['user-details']" (click)="showMenu=false">
          My Profile
        </li>
        <li [ngClass]="{active : (submenu=='faq'), 'list-group-item' :true }" [routerLink]="['/faq']"
          (click)="showMenu=false">
          FAQ
        </li>
        <li [ngClass]="{active : (submenu=='aboutus'), 'list-group-item' :true }" [routerLink]="['/about-us']"
          (click)="showMenu=false">
          About Us
        </li>
        <li [ngClass]="{active : (submenu=='contactus'), 'list-group-item' :true }" [routerLink]="['/contact-us']"
          (click)="showMenu=false">
          Contact Us
        </li>
        <li *ngIf="loginStatus" style="cursor: pointer;" class="list-group-item" (click)="logout()">
          Log out
        </li>
      </ul>
    </div>
  </div>
