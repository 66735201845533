import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-progress-header-bar',
  templateUrl: './progress-header-bar.component.html',
  styleUrls: ['./progress-header-bar.component.scss']
})
export class ProgressHeaderBarComponent implements OnInit {

  logginStatus: boolean;
  submenu: string = '';
  route: string;
  constructor(private location: Location, private router: Router,
    public deviceService: DeviceDetectorService) {
    router.events.subscribe((val) => {
      if (location.path() != '') {
        this.route = location.path();
        if (this.route.indexOf('search-hotel') > -1) {
          this.submenu = 'search-hotel';
        } else if (this.route.indexOf('/search-result') > -1) {
          this.submenu = 'choose-hotel';
        } else if (this.route.indexOf('/room-result/') > -1) {
          this.submenu = 'choose-room';
        } else if (this.route.indexOf('booking-detail') > -1) {
          this.submenu = 'enter-details';
        } else if (this.route.indexOf('payment') > -1) {
          this.submenu = 'payment-deatil';
        } else {
          this.submenu = '';
        }
      }
    });

  }

  ngOnInit() {
    let userStatus;
    localStorage['angooseisLoggedIn'] ? userStatus = JSON.parse(localStorage['angooseisLoggedIn']) : userStatus = false;
    if (userStatus) {
      this.logginStatus = true;
    }
    else {
      this.logginStatus = false;
    }
  }

}
