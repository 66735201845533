<div class="container" id="faq-page">
  <div class="row">
    <div class="col-12">
      <h4 class="font-weight-bold my-3">Frequently Asked Questions</h4>
    </div>
    <div *ngFor="let data of faqs" class="col-12">
      <h5 class="font-weight-bold">{{data.type}}</h5>
      <div *ngFor="let faq of data.faqs" class="my-4 text-justify text-dark">
        <div class="mt-1 d-flex h6">
          <div class="pt-2 pr-2">
            <b class="pr-1">Q</b>
          </div>
          <div class="p-0" style="line-height: 33px;letter-spacing: .8px;word-spacing: 1.2px;">
            <span [innerHtml]="faq.question"></span>
          </div>
        </div>
        <div class="mt-1 d-flex h6">
          <div class="pt-2 pr-2">
            <b>A</b>
          </div>
          <div class="p-0 px-1" style="line-height: 33px;letter-spacing: .8px;word-spacing: 1.2px;"
            [innerHtml]="faq.answer"></div>
        </div>
      </div>
    </div>
  </div>
</div>
