import {Component,Input,OnInit} from '@angular/core';
import {Router,NavigationEnd,Data} from '@angular/router';
import {Subscription} from 'rxjs';
import {SiteConfig} from '../../../config/site-config';
import {DataService} from '../../../services/data-service/data.service';
import {DeviceDetectorService} from 'ngx-device-detector';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isLoggedIn: boolean;
  currentUser: any;
  state=1;
  user: any={};
  isForm: boolean=true;
  errorMessage: string='';
  logo: boolean=true;
  mobileMenu=false;
  private _routerSub=Subscription.EMPTY;

  constructor(private router: Router,private dataService: DataService,public deviceDetector: DeviceDetectorService) {
    this.mobileMenu=deviceDetector.isMobile()||deviceDetector.isTablet();
  }

  ngOnInit(): void {
    this.router.events.subscribe(eve => {
    });
  }
  get loginStatus() {
    return this.dataService.loginStatus
  }
  get userData() {
    return this.dataService.userData
  }
  get userName() {
    if(this.dataService&&this.dataService.userData&&this.dataService.userData.name) {
      const name=this.dataService.userData.name.split(' ');
      return name[0];
    }
  }

  logout() {
    localStorage.removeItem(SiteConfig.localStorageNames.viagooseLoginStatus);
    localStorage.removeItem(SiteConfig.localStorageNames.viagooseLoginData);
    sessionStorage.removeItem('FAQ');
    this.dataService.loginStatus=false;
    this.dataService.userData=null;
    this.router.navigateByUrl('/login');
  }
}
