export const SiteConfig={
  localStorageNames: {
    siteAuthToken: "siteAuthToken",
    viagooseLoginData: "viagooseLoginData",
    viagooseLoginStatus: "viagooseLoginStatus",
    viagooseHotelData: "viagooseHotelData",
    viagooseRoomCode: "viagooseRoomCode",
    viagooseRateKey: "viagooseRateKey",
    viagooseGuestData: "viagooseGuestData",
    viagooseBookingData: "viagooseBookingData",
    viagooseChildAges: "viagooseChildAges",
    viagoosePaxDetails: "viagoosePaxDetails",
    viagooseSelectedRates: "viagooseSelectedRates",
    viagooseRemark: "viagooseRemark",
    pointAllocation: "pointAllocation",
    isPointUser: "isPointUser"
  }
}
