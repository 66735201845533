<div class="container" *ngIf="!deviceService.isMobile()">
    <div class="v-progress" *ngIf="submenu != ''">
        <ol id="process-steps" class="mobilehidden">
            <li [class.complete]="submenu=='search-hotel'">
                Destination</li>
            <li [class.complete]="submenu=='choose-hotel'">
                Hotels</li>
            <li [class.complete]="submenu=='choose-room'">
                Rooms</li>
            <!-- <li [class.complete]="submenu=='choose-options'">
                Options</li> -->
            <li [class.complete]="submenu=='enter-details'">
                Your Details</li>
            <li class="end-point" [class.complete]="submenu=='payment-deatil'">
                Book</li>
            <!-- <li class="end-point" [class.complete]="submenu=='booking-status'">
                <span>
                    Confirmation
                </span>
            </li> -->
        </ol>
    </div>
</div>
