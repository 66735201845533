import {Component,Input,OnInit} from '@angular/core';
import {ApiService} from 'src/app/services/api-service/api.service';
import {DataService} from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-point-balance',
  templateUrl: './point-balance.component.html',
  styleUrls: ['./point-balance.component.scss']
})


export class PointBalanceComponent implements OnInit {

  public showBalance: boolean=false;
  public userPoints: any=false;
  public charities: Array<object>;

  public charityTotals: Array<object>;
  public charitySum: number=0;

  public total_retailer_earnings: number=0;


  constructor(private apiService: ApiService,private dataService: DataService) {
    this.pointHistory();
  }

  ngOnInit(): void {
    this.dataService.updateModalData.subscribe(data => {
      if(data) {
        this.pointHistory();
      }
    })
  }

  get user() {
    return this.dataService.userData;
  }


  isDonatedTo(charityId,donatedCharities): object {
    let donated: any={name: '',points: '',status: false};
    donatedCharities.forEach((ch) => {
      if(ch['id']==charityId) {
        donated.points=ch['points'];
        donated.name=ch['name'];
        donated.status=true;
      }
    });
    return donated;
  }

  pointHistory() {
    this.charity();
    this.apiService.get('customer/getreports').subscribe(resp => {
      this.total_retailer_earnings=0;
      this.userPoints=resp;
      this.userPoints.charity_donations.sort((a,b) => {return (a['name'].toLowerCase()<b['name'].toLowerCase()? -1:1);});
      this.charitySum=0;
      this.userPoints.charity_donations.forEach(ch => {
        this.charitySum+=ch.total/100;
      });

      this.userPoints.data.forEach(row => {
        this.total_retailer_earnings+=parseFloat(row.retailer_earnings);
      })

    });
  }

  formatDate(date: string): string {
    let d=new Date(date);
    let ye=new Intl.DateTimeFormat('en',{year: 'numeric'}).format(d);
    let mo=new Intl.DateTimeFormat('en',{month: 'short'}).format(d);
    let da=new Intl.DateTimeFormat('en',{day: '2-digit'}).format(d);
    return `${da}-${mo}-${ye}`;
  }

  charity() {
    if(!this.user) return;
    this.charityTotals=[];
    this.apiService.get('customer/getcharities').subscribe((resp: any) => {
      this.charities=resp.data;
      this.charities.sort((a,b) => {return (a['full_name'].toLowerCase()<b['full_name'].toLowerCase()? -1:1);});
      this.charities.forEach((ch,i) => {
        if(i<2) this.charityTotals[i]={id: parseInt(ch['id']),name: ch['full_name'],total: 0};
      });
      this.charityTotals[2]={id: 0,name: 'others',total: 0};
    });
  }

  sum(donations: Array<object>) {
    let dtotal=0;
    donations.forEach(d => {
      dtotal+=parseInt(d['points']);
    });
    return dtotal.toLocaleString();
  }

  format(num: any,p?: boolean) {
    if(typeof num=='string') num=parseFloat(num);
    if(p!=true) num=num.toFixed(2);
    return num? num.toLocaleString():num;
  }
}
