import {NgModule} from '@angular/core';
import {Routes,RouterModule,PreloadAllModules} from '@angular/router';
import {AuthGuard,LoginGuard} from './guards/authguard';
import {FaqComponent} from './components/faq/faq.component';


const routes: Routes=[
  {path: '',redirectTo: 'search-hotel',pathMatch: "full"},
  {
    path: 'home',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'register',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'how-it-works',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/how-it-works/how-it-works.module').then(m => m.HowItWorksPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-condition',
    loadChildren: () => import('./pages/terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionPageModule)
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'search-hotel',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'search-result/:searchparams',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/search-result/search-result.module').then(m => m.SearchResultPageModule)
  },
  {
    path: 'search-result',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/search-result/search-result.module').then(m => m.SearchResultPageModule)
  },
  {
    path: 'room-result',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/room-result/room-result.module').then(m => m.RoomResultPageModule)
  },
  {
    path: 'room-result/:hotelid',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/room-result/room-result.module').then(m => m.RoomResultPageModule)
  },
  {
    path: 'booking-detail/:roomid/:bookingdetails',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/booking-detail/booking-detail.module').then(m => m.BookingDetailsPageModule)
  },
  {
    path: 'payment/:roomid/:bookingdetails',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'confirmation/:status',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/confirmation/confirmation.module').then(m => m.ConfirmationPageModule)
  },
  {
    path: 'confirmation/:status',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/confirmation/confirmation.module').then(m => m.ConfirmationPageModule)
  },
  {
    path: 'booking-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/booking-history/booking-history.module').then(m => m.BookingHistoryPageModule)
  },
  {
    path: 'user-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/user-details/user-details.module').then(m => m.UserDetailsPageModule)
  },
  // directs all other routes to the main page
  {path: '**',redirectTo: 'search-hotel'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
